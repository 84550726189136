import React, { Component } from 'react'
import { Container } from 'reactstrap'
import headImg from 'img/usuario.svg'
import { Layout } from 'core/components'
import { ABanner, AAnimate } from 'shared/components/common'
import { Recoberpass } from 'modules'

interface LoginHeaderProps {
  title?: string
}

interface LoginProps {
  pageContext: {
    locale: string
  }
}

interface LoginState {
  mounted: boolean
}

class Recoverpassword extends Component<LoginHeaderProps & LoginProps, LoginState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale },
      title
    } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ABanner
            title={title || 'Recuperar contraseña'}
            titleClassName="big-white-op"
            content={<img className="ml-auto" src={headImg} />}
          />
          <Container>
            <Recoberpass />
          </Container>
        </AAnimate>
      </Layout>
    )
  }
}

export default Recoverpassword
